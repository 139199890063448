























































































import { Invoice } from '@app/models';
import fb from 'firebase/app';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class _Invoice extends Vue {
  public get date() {
    const date = this.invoice.date.toDate();
    const year = date.getFullYear();
    const month = (1 + date.getMonth()).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${day}-${month}-${year}`;
  }

  public get fields() {
    return [
      {
        key: 'description',
        label: 'Beschrijving',
        thClass: 'th-description',
        tdClass: 'td-description',
      },
      {
        key: 'desc',
        label: '',
        thClass: 'th-desc',
        tdClass: 'td-desc',
      },
      {
        key: 'price',
        label: 'Prijs',
        thClass: 'th-price',
        tdClass: 'td-price',
      },
    ];
  }

  public get invoice(): Invoice {
    if (!this.$route.query.invoice) {
      return {
        address: 'Street 96',
        company: '<company-id>',
        date: fb.firestore.Timestamp.now(),
        id: '<invoice-id>',
        license: '',
        name: 'Name',
        nr: '000000',
        postalCode: '1221 CB',
        price: 0,
        state: 'Bilthoven',
        structure: [],
      };
    }

    const json = JSON.parse(this.$route.query.invoice as string);
    const { _seconds, _nanoseconds } = json.date;
    const date = new fb.firestore.Timestamp(_seconds, _nanoseconds);
    return { ...json, date };
  }

  public get items() {
    const structure = this.invoice.structure!;
    const price = structure.reduce((acc, val) => acc + val.price, 0);
    const vat = price * 0.21;
    const exVat = price;

    return [
      { description: '', desc: '', price: '' },
      ...structure
        .filter(s => !!s.price)
        .map(s => ({
          description: s.name,
          desc: '',
          price: this.formatPrice(s.price),
        })),
      { description: ' ', desc: '', price: '' },
      { description: '', desc: 'Subtotaal', price: this.formatPrice(exVat) },
      { description: '', desc: 'Btw (21%)', price: this.formatPrice(vat) },
      {
        description: '',
        desc: 'Totaal verschuldigd',
        price: this.formatPrice(price + vat),
      },
    ];
  }

  private formatPrice(price: number) {
    const op: Intl.NumberFormatOptions = { style: 'currency', currency: 'EUR' };
    return new Intl.NumberFormat('nl-NL', op).format(price);
  }
}
